import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"
import AvenueImg from "../AvenueImg"
import { Checkbox } from "../Form/Checkbox"

export const ContactFormContainer = styled(MainColumn)`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  @media ${device.desktop} {
    margin-bottom: 120px;
    margin-top: 20px;
    flex-direction: row;
  }
`
export const FormContainer = styled.div`
  padding: 27px 30px 40px 27px;
  @media ${device.desktop} {
    width: calc(66.6666667% - 20px);
    padding: 35px 45px;
  }

  @media ${device.large} {
    padding: 55px 70px 50px 70px;
  }
`

export const MapColumn = styled.div`
  width: 100%;
  @media ${device.desktop} {
    width: calc(33.33333333% + 20px);
    border-left: 1px solid #dce0e7;
  }
`

export const ContactInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 30px;
  padding-bottom: 20px;

  @media ${device.large} {
    padding: 36px 40px;
    padding-bottom: 24px;
  }

  > div {
    margin-bottom: 13px !important;
    @media ${device.desktop} {
    }
  }
`

export const MapImg = styled(AvenueImg)`
  img {
    height: 480px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;

    @media ${device.tablet} {
      height: 100% !important;
    }

    @media ${device.desktop} {
      height: 480px !important;
    }
  }
`

export const ContactCheckbox = styled(Checkbox)`
  height: 32px;
  align-items: center;
  padding-left: 44px;
  margin-bottom: 15px;

  @media ${device.desktop} {
    width: ${props => props.subscribe ? "100%" : "50%"};
    float: left;

    margin-top: ${props => props.subscribe ? "20px" : "0"};
    margin-bottom: ${props => props.subscribe ? "12px" : "15px"};
    &.right {
      float: right;
    }
  }
  span {
    height: 32px;
    width: 32px;
  }
  input {
    width: 100%;
    height: 100%;
  }

  label {
    font-weight: 400;
    font-size: ${props => props.subscribe ? "14px" : "16px"};

    @media ${device.desktop} {
      font-size: 17px;
    }

    @media ${device.large} {
      font-size: 18px;
    }
  }
`

export const SubmittedMessage = styled.h3`
  font-size: 22px;
  line-height: 120%;
  @media ${device.desktop} {
    font-size: 28px;
  }

  br {
    /* display: none; */
    @media ${device.desktop} {
      display: block;
    }
  }
`

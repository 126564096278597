import React from "react"
import Layout from "../components/layout"
import { InternalPage } from "components/InternalPage"
import { ContactForm } from "components/ContactForm"
import SEO from "../components/seo"

const ContactPage = ({ pageContext }) => {
  const { title, featuredImage, excerpt, previewSQ, depth } = pageContext

  return (
    <Layout
      internal
      landing
      headerImg={featuredImage}
      preview={previewSQ}
      depth={depth}
    >
      <SEO title={title} image={featuredImage?.sourceUrl} />
      <InternalPage landing title={title} excerpt={excerpt} />
      <ContactForm />
    </Layout>
  )
}

export default ContactPage

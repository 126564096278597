import React, { useState, useEffect } from "react"
import {
  CustomForm,
  SubmitButton,
  FormInputHeader,
  FormTextInput,
} from "../FormEg/style"

import {
  ContactFormContainer,
  FormContainer,
  MapColumn,
  ContactInfoWrap,
  MapImg,
  ContactCheckbox,
  SubmittedMessage,
} from "./style"

import { ContactUsContainer, NewIcon } from "../Footer/footerMenuStyles"
import { Icon } from "utils/helpers/getIcon"
import { useStaticQuery, graphql } from "gatsby"

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false)

  const [formstate, setFormState] = useState({
    action: "",
    xnQsjsdp: "",
    xmIwtLD: "",
  })

  useEffect(() => {
    setFormState({
      action: "https://crm.zoho.com/crm/WebToLeadForm",
      returnUrl: `${window.location.href.split("#")[0]}#thanks`,
      xnQsjsdp:
        "e3b2a08acb3921bd870e27815f22871feed208ac96419c63cb6645312918b279",
      xmIwtLD:
        "dd2ec1679d4b24ac5474202a1c89b0376f305631198af7c53783aebc699dcce2",
    })

    if (window.location.hash === "#thanks") {
      setSubmitted(true)
    }
  }, [])

  const data = useStaticQuery(
    graphql`
      {
        mapImg: file(relativePath: { eq: "contact-map.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        wpgraphql {
          siteOptions {
            siteSettings {
              title
              phoneNumber
              mapLink
              lefttext
              fieldGroupName
              email
              description
              address
              acknowledgement
            }
          }
        }
      }
    `
  )

  const contactData = data.wpgraphql.siteOptions.siteSettings

  const phoneIcon = <Icon name="phone-color" />
  const homeIcon = <Icon name="home" />
  return (
    <ContactFormContainer id="thanks">
      <FormContainer>
        {submitted && (
          <SubmittedMessage>
            Thanks for getting in contact! <br />
            We'll be in touch shortly.
          </SubmittedMessage>
        )}
        {!submitted && (
          <CustomForm
            noValidate
            noAjax
            action={formstate.action}
            name="contact"
          >
            <FormTextInput
              name="Lead Source"
              type="hidden"
              value="Website enquiry"
            />
            <FormTextInput
              name="xnQsjsdp"
              type="hidden"
              value={formstate.xnQsjsdp}
            />
            <FormTextInput
              name="xmIwtLD"
              type="hidden"
              value={formstate.xmIwtLD}
            />
            <FormTextInput name="actionType" type="hidden" value="TGVhZHM=" />
            <FormTextInput name="zc_gad" type="hidden" value="" />
            <FormTextInput
              name="returnURL"
              type="hidden"
              value={formstate.returnUrl}
            />

            <FormInputHeader>First name</FormInputHeader>
            <FormTextInput name="First Name" error="Please enter a username" />
            <FormInputHeader>Last name</FormInputHeader>
            <FormTextInput name="Last Name" error="Please enter a username" />
            <FormInputHeader>Email address</FormInputHeader>
            <FormTextInput
              type="email"
              name="Email"
              error="Please enter a valid email"
            />
            <FormInputHeader>Mobile</FormInputHeader>
            <FormTextInput
              type="phone"
              name="Mobile"
              error="Please enter a valid phone"
            />
            <FormInputHeader marginBottom>I’m interested in</FormInputHeader>

            <ContactCheckbox
              name="LEADCF106"
              label="Business Advice"
              value="on"
              type="checkbox"
            />
            <ContactCheckbox
              name="LEADCF103"
              value="on"
              label="Property Investment"
              type="checkbox"
            />
            <ContactCheckbox
              name="LEADCF104"
              value="on"
              label="Business Accounting / Tax"
              type="checkbox"
              className="right"
            />
            <ContactCheckbox
              name="LEADCF102"
              value="on"
              label="Mortgage / Finance"
              type="checkbox"
            />
            <ContactCheckbox
              name="LEADCF108"
              value="on"
              label="Personal Accounting / Tax"
              type="checkbox"
            />
            <ContactCheckbox
              name="LEADCF109"
              value="on"
              label="Financial Planning"
              type="checkbox"
              className="right"
            />
            <FormInputHeader marginTop>Your message</FormInputHeader>
            <FormTextInput
              name="LEADCF5"
              type="textarea"
              error="Please enter a message"
            />
            <ContactCheckbox
              subscribe
              name="LEADCF101"
              label="Yes, I'd like to receive news and updates from Johnston Grocke"
              value="on"
              type="checkbox"
            />

            <FormTextInput
              type="hidden"
              name="eo"
              value="14f4ec16431e0686150daa43f3210513"
            />
            <FormTextInput type="hidden" name="te" value="true" />

            <img
              id="tr_img_dd2ec1679d4b24ac5474202a1c89b0376f305631198af7c53783aebc699dcce2"
              src="https://crm.zohopublic.com/crm/WebFormAnalyticsServlet?rid=dd2ec1679d4b24ac5474202a1c89b0376f305631198af7c53783aebc699dcce2gide3b2a08acb3921bd870e27815f22871feed208ac96419c63cb6645312918b279gida378f81585080d3b6fb1acbec6638b71gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513"
              style={{ display: "none" }}
              alt=""
            />

            <SubmitButton submit label="Submit" />
          </CustomForm>
        )}
      </FormContainer>
      <MapColumn>
        <MapImg
          fluid={{ ...data.mapImg, sizes: "(min-width: 1024px) 400px, 100vw" }}
        />

        <ContactInfoWrap>
          <ContactUsContainer>
            <NewIcon>{homeIcon}</NewIcon>
            <a
              href={contactData.mapLink}
              target="_blank"
              rel="noopener noreferrer"
              className="first"
            >
              {contactData.address}
            </a>
          </ContactUsContainer>

          <ContactUsContainer>
            <NewIcon>{phoneIcon}</NewIcon>
            <a href={`tel:${contactData.phoneNumber}`}>
              {contactData.phoneNumber}
            </a>
          </ContactUsContainer>
        </ContactInfoWrap>
      </MapColumn>
    </ContactFormContainer>
  )
}

export default ContactForm
